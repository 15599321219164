@tailwind base;
@tailwind components;
@tailwind utilities;

#root{
    display: flex;
    flex-direction: column;
    min-height: 100vh; 
    main{
        .text-center {
    text-align: center;
    color: white;
}
        flex: 1 0 auto;
    }
}

.-mx-4 {
    margin-left: 8rem;
    margin-right: 4rem;
}
.text-center {
    text-align: center;
    color: white;
}
@media (min-width: 768px){
    .md\:mr-0 {
        margin-right: 13px;
    }
}
.list-item {
    color: black !important;
  }
